import './EasySoftwareNavigator.scss'

import React, { FunctionComponent } from 'react'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

interface NavigatorSubcategoryType {
  displayText: string
  navigatorSubcategoryText: PuxWysiwygHtmlBodyType
}
interface NavigatorCategoryType {
  displayText: string
  navigatorSubcategories: {
    contentItems: [NavigatorSubcategoryType]
  }
}
export interface EasySoftwareNavigatorType {
  contentItemId?: string
  displayText: string
  navigatorCategories: {
    contentItems: [NavigatorCategoryType]
  }
}

const rootClass = `navigator`

const EasySoftwareNavigator: FunctionComponent<EasySoftwareNavigatorType> = (
  props
) => {
  return (
    <div className={rootClass}>
      <h2 className={`${rootClass}-title`}>{props.displayText}</h2>
      <div className={`${rootClass}-items`}>
        {props.navigatorCategories &&
          props.navigatorCategories.contentItems &&
          props.navigatorCategories.contentItems.map((cat, catIdx) => (
            <div className={`${rootClass}-category`} key={catIdx}>
              <div className={`${rootClass}-category-heading`}>
                <h3 className={`${rootClass}-category-title`}>
                  {cat.displayText}
                </h3>
              </div>
              <div className={`${rootClass}-subcategories`}>
                {cat.navigatorSubcategories &&
                  cat.navigatorSubcategories.contentItems &&
                  cat.navigatorSubcategories.contentItems.map(
                    (subcat, subcatIdx) => (
                      <div
                        className={`${rootClass}-subcategory`}
                        key={subcatIdx}
                      >
                        <h4 className={`${rootClass}-subcategory-title`}>
                          {subcat.displayText}
                        </h4>
                        <div className={`${rootClass}-subcategory-content`}>
                          <PuxWysiwyg
                            inline
                            content={subcat.navigatorSubcategoryText}
                          />
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default EasySoftwareNavigator
